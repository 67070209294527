import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/global/layout"
import Intro from "../components/collections/intro"
import FilterLayout from "../components/filter/filter-layout"

const Wine = ({
  data: {
    datoCmsWine,
    parent,
    regionChildren,
    regionParents,
    allPortalWine,
    allPortalGrape,
    allPortalTag,
    allPortalPairing,
    datoCmsCellar
  },
  pageContext: { productContext },
}) => {
  const productData = {
    allWines: allPortalWine.nodes,
    allGrapeTypes: allPortalGrape.nodes,
    allWineTags: allPortalTag.nodes,
    allPairingTypes: allPortalPairing.nodes,
    allWineTypes: productContext.allWineTypes,
  }

  const { title, id, description, featuredWines } = datoCmsWine

  const breadcrumbs = [
    { title: parent.title, id: parent.id },
    { title: title, id: id },
  ]

  return (
    <Layout
      seoTitle={`iHeartWine | Shop Wine Online | ${title}`}
      seoDescription={description}
    >
      <div>
        <Intro
          title={title}
          description={description}
          parentTitle={"Wine"}
          items={featuredWines}
          light={(featuredWines.length === 2) ? true : false}
          breadcrumbs={breadcrumbs}
        />
        <FilterLayout
          productContext={productData}
          defaultFilters={[{ id: title, type: "category", name: title }]}
          regionChildren={regionChildren.nodes}
          regionParents={regionParents.nodes}
          stickerText={datoCmsCellar.stickerText}
        />
      </div>
    </Layout>
  )
}

export default Wine

export const query = graphql`
  query WineChildQuery($id: String!) {
    datoCmsWine(originalId: { eq: $id }) {
      title
      description
      featuredWines {
        portalId
        title
        subHeading
        text
        imageOverride {
          gatsbyImageData(
            layout: CONSTRAINED
            imgixParams: { h: "460", fit: "crop", w: "460", q: 60 }
          )
          alt
        }
      }
      slug
      id: originalId
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
    parent: datoCmsCollectionParent(originalId: { eq: "38380812" }) {
      title
      slug
      id: originalId
    }
    regionChildren: allDatoCmsRegionChild {
      nodes {
        id: portalRegionId
        title
        parentRegion {
          id: originalId
        }
      }
    }
    regionParents: allDatoCmsRegionParent(sort: { fields: position }) {
      nodes {
        id: originalId
        title
      }
    }
    allPortalWine {
      nodes {
        ...AllPortalWine
      }
    }
    allPortalGrape {
      nodes {
        ...AllPortalGrape
      }
    }
    allPortalTag {
      nodes {
        ...AllPortalTag
      }
    }
    allPortalPairing {
      nodes {
        ...AllPortalPairing
      }
    }
    datoCmsCellar {
      stickerText
    }
  }
`
